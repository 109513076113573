.AAA {
  
}

.gpt3__header {
    
}

.contact-container {
    margin-top:150px ;
    margin-bottom:300px ;

}

.section__headline {
    color:  white;
    font-family: var(--font-family);
    font-size: 40px;
}

.gpt3__header-content__v2 {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
   
    margin-top: 100px;
    margin-right: 5rem;
    margin-left: -105px;
}

.gpt3__header-content__v2 h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 34px;

    letter-spacing: -0.04em;
}

.gpt3__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.gpt3__header-content__input {
    width: 100%;
    flex-direction: column;
    display: flex;
    background-color: rgba(69, 60, 60, 0.303);
    border: var(--color-text) 1px solid;
    border-radius: 20px;
    padding: 10px 10px 70px 10px;
    margin-top: 20px;
    min-height: 300px; /* Set a minimum height */

}




.gpt3__header-content__input input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
 
    color: var(--color-text) !important;
    border: none;
    background-color: transparent;
    padding: 0 1rem;
    outline: none;
    margin-left: -14px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.success-slide h2 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25px;

    letter-spacing: -0.04em;
    color: #fff;
}

.form__p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);
    margin-top: 1.5rem;
}

.book-meeting {
    font-family: var(--font-family);
    color: white;
    background-color: transparent;
    border-radius: 10px;
    height:80px;
    width:100%;
    border: 1px solid rgba(255, 255, 255, 0.857);
    margin-top: 20px;
    box-shadow: 0px 0px 10px 3px var(--color-text);

}

.button-content {
    display: flex;
    flex-direction: row;
justify-content: space-between;
}

.bm-txt-p {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

#p-one {
 font-weight: bold;
 float: left;
 margin-left: 10px;
}
#p-two {
color: rgba(255, 255, 255, 0.857);
float: left;
margin-left: 10px;
}

.button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-arrow {
    margin-right: 10px;
}

.FOBM__intro__contact__form {
    margin-left: 100px;
    margin-right: 100px;
    width: 1000px;
}

.question-header {
    color:  #fff !important;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    margin-top: 10px;
}

.gpt3__header-content__input input {
    margin-top: 20px;
    margin-bottom: -5px;
}

.gpt3__header-content__input ::placeholder {
    color: var(--color-text);
    }

.underline {
    margin-bottom: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2.5px;
    background:var(--gradient-text);
}

.button-options p {
    color:  #fff !important;
    font-family: var(--font-family);
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}

.button-options button {
    background: transparent !important;
    color: var(--color-text) !important;
    font-family: var(--font-family);
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    padding: 0.5rem 1rem !important;
    /* border-radius: 2rem !important; */
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--color-text) !important;
    outline: none !important;
    cursor: pointer !important;
    min-width: 150px !important;
    
  }
  
  .button-options button.selected {
    color: black !important;
    background-color: var(--color-text) !important;
  }
  
  

.message-box {
    flex: 2;
    margin-top: 5px;
    width: 100%;
    min-height: 100px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__input button {
    flex: 0.6;
    width: 20%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--gradient-text);
    border: 2px solid var(--gradient-text);
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;
    margin-top: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.gpt3__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.gpt3__header-content__people img {
    width: 181.79px;
    height: 38px;
}

.gpt3__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.gpt3__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gpt3__header-image img {
    width: 100%;
    height: 100%;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: -300px;
    background: var(--gradient-text);
    padding: 5px 3px 3px 2px;
    z-index: 999; /* Ensure it covers everything */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    border: 2px solid white;
   

}

@media (min-width:750px) {.overlay{margin-left: 0px;}}
@media (min-width:1200px) {.overlay{margin-left: -50px;}}
  
  .calendar-box {
    display: none;
  
    
  }
  
  .frame {
    text-align: center; /* Center the content horizontally */
   
  }
  
  #frame {
  width: 320px;
  height: 650px;
  border-radius: 25px;
  border: none;
  }
  @media (min-width:385px) {#frame {width: 350px;}}
  @media (min-width:414px) {#frame {width: 365px;}}
  @media (min-width:470px) {#frame {width: 410px;}}
  @media (min-width:500px) {#frame {width: 490px;}}
  @media (min-width:600px) {#frame {width: 500px;}}
  @media (min-width:700px) {#frame {width: 600px;}}
  @media (min-width:800px) {#frame {width: 700px;}}
  @media (min-width:900px) {#frame {width: 830px;}}
  @media (min-width:1000px) {#frame {width: 900px;}}
  @media (min-width:1100px) {#frame {width: 1000px;}}
  @media (min-width:1200px) {#frame {width: 1000px;}}
  @media (min-width:1300px) {#frame {width: 1200px;}}
  @media (min-width:1400px) {#frame {width: 1300px;}}
  @media (min-width:1500px) {#frame {width: 1400px;}}
  @media (min-width:1600px) {#frame {width: 1500px;}}
  @media (min-width:17000px) {#frame {width: 1600px;}}



  
  .box-and-arrow {
    display: flex;
    flex-direction: column; /* Center the content vertically */
    align-items: center;
    justify-content: center;
  }
  
  .calendar-close {
    fill: white;
    width: 50px;
    height: 50px;
    background-color: rgba(33, 33, 33, 0.163);
    padding: 10px 10px 10px 10px;
    border-radius: 50px;
    margin-left: 2px;
  }

  /*CALENDY STYLING BELOW*/


/*
CONTACT STYLING BELOW
*/

#meeting-photo {
    height:60px;
    width:60px;
    border-radius: 50px;
    border: 3px solid var(--color-subtext);
    margin-top: 0px;
    margin-right: -130px;

}

@media screen and (max-width: 1167px) {

    #meeting-photo {
    
        margin-right: -60px;
    
    }

}

@media screen and (max-width: 1150px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content__v2 {
        margin: 0 0 3rem;
        flex-direction: column;
    }
    .book-meeting {
        width: 400px;
    }

    #meeting-photo {
    
        margin-right: -60px;
    
    }
}

@media screen and (max-width: 830px) {
    .gpt3__header-content__v2 h1 {
        font-size: 28px;
        margin-top: 50px;
    }

    .button-options button {
     
        display: block; 
   
     
    }

    .gpt3__header-content__v2 p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .gpt3__header-content__people {
        flex-direction: column;
    }

    .gpt3__header-content__people p {
        margin: 0;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button,
    .message-box {
        font-size: 16px;
        line-height: 24px;
    }

    .book-meeting {
        width: 100%;
       
    }

    #meeting-photo {
  
        margin-right: 2px;
    }

}



@media (max-width: 475px) {

 

    .gpt3__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }

    .book-meeting {
        width: 99%;
        height: 100%;
    }

  

    #meeting-photo {
        height:60px;
        width:60px;
        border-radius: 50px;
        border: 3px solid var(--color-subtext);
       margin-top: 0px;
        margin-right: 2px;
    }

    #p-one{
        font-size: 14px;
    }
    #p-two{
        font-size: 12px;
    }

}



@media screen and (max-width: 1050px) {
    .button-options button {
        font-size: 10px !important;
  
   
     
    }

  
}

@media screen and (max-width: 1050px) {
    .FOBM__intro__contact__form  {
      width: 100%;
      margin-left: 0px;

     
    }
}

@media screen and (max-width: 1050px) {
    .question-header  {
      width: 100%;
      margin-top: 30px;
      margin-bottom: -10px;
    }

  

          
}
