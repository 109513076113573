.b__blog {
    display: flex;
    flex-direction: column;    
    max-width: 2458px;
    margin-top: 200px;
}



.b__blog-heading {
    width: 100%;
    text-align: left;

    margin-bottom: 5rem;
}

.b__blog-heading h1 {
    font-size: 62px;
    line-height: 75px;
    font-family: var(--font-family);
    font-weight: 800;
}

.b__blog-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (min-width: 990px) {
    .b__blog-container {

        flex-direction: row;
    }
}

.b__blog-container_groupA {

    width:350px;
    height:500px;
    margin-bottom: 180px;
}


@media (min-width: 990px) {
    .b__blog-container_groupA {
        width:400px;
        height:590px;
        flex-direction: row;
        margin-right: 30px;
        margin-top: -10px;
    }
}


.b__blog-container_groupB {
display: flex;
flex-direction: column;
justify-content: space-between;

}

@media (min-width: 990px) {
    .b__blog-container_groupB {

        flex-direction: row;
    }
}
.twitter-tweet {
    width:100px;
    height:300px;
}

.facebook__post {
    border-radius: 20px;
    width:350px;
    height:500px;
    margin-right: 10px;
    margin-bottom: 80px;
}

@media (min-width: 990px) {
    .facebook__post {
        width:400px;
        height:500px;
    }
}

@media screen and (max-width: 990px) {



   

    .b__blog-container_groupA .gpt3__blog-container_article  {
       width: 48%;
    }

    .b__blog-container_groupA .gpt3__blog-container_article-image {
        height: 250px;
    }
}

@media screen and (max-width: 50000px) {
    .b__blog-container_groupB {
      
    }

   

    .b__blog-heading h1 {
        font-size: 46px;
        line-height: 52px;
    }
}

@media screen and (max-width: 550px) {
    .b__blog-heading h1 {
        font-size: 34px;
      
    }

    .facebook__post {
 

}

}