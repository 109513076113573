.login {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login h1 {
    color: white;
    margin-bottom: 20px;

}

.login input::placeholder {
    color: var(--color-text);

}

.login input {
    flex: 2;
    width: 110%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: rgba(0, 0, 0, 0.107);
    border: 2px solid var(--color-text);
    padding: 0 1rem;
    outline: none;
    color: var(--color-text);
    margin-top: 5px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 10px;
}

.login hr {
    width: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.logo-note {
    color: white;
    height: 60px;
    display: flex;
    flex-direction: row;
     align-items: center;
 
    font-size: 10px;
}

#logo {
    height: 30px;
    width: 30px;
    margin-right: 5px;

}

#sign-in {
    flex: 2;
    width: 110%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: rgba(0, 0, 0, 0.107);
    border: 2px solid var(--color-text);
    padding: 0 1rem;
    outline: none;
    color: black;
    background-color: var(--color-text);
    margin-top: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-bottom: 10px;
}


