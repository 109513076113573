#karen-container {
    box-shadow: 0 0 150px rgba(225, 6, 6, 0.5);
    border-radius: 15px;
    margin: 150px auto 0; /* Top margin of 150px and auto for left and right margins to center */
    justify-content: center;
    width: 80%;
}

.quote-karen {
    margin-top: 50px;
    position: relative;
    width: 90%;
    margin-top: 60px;
  }

  .sub-quote-karen {
    margin-top: -90px;
    margin-left: 40px;
    
  }

.quotation-mark-v1-karen {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
font-family: var(--font-family);
background: linear-gradient(89.97deg, #de130c 1.84%, #fb9f00 102.67%);
-webkit-background-clip: text;
background-clip: text;
color: transparent;
font-weight: bold;
font-size: 160px;
}

.quotation-mark-v2-karen {
    top: 55%;
    left: 75%;
    position: absolute;
    transform: translateY(50%);
font-family: var(--font-family);
background: linear-gradient(89.97deg, #de130c 1.84%, #fb9f00 102.67%);
-webkit-background-clip: text;
background-clip: text;
color: transparent;
font-weight: bold;
font-size: 160px;
text-align: end;
}


.quotation-mark-v3-karen {
font-family: var(--font-family);
background: linear-gradient(89.97deg, #de130c 1.84%, #fb9f00 102.67%);
-webkit-background-clip: text;
background-clip: text;
color: transparent;
font-weight: bold;
font-size: 160px;
margin-left: 90%;

}

.quote-h1-karen   {
    font-family: var(--font-family);

    font-weight:bold;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: -50px;
    justify-content: center;
    margin-left: 40px;
}

.quote-h2 {}

.quote-h3 {
    font-weight:400;
}

.quote-button {
    margin-top: 20px;
    font-family: var(--font-family);
    border-radius: 15px;
    padding: 10px;
    background: linear-gradient(89.97deg, #de130c 1.84%, #fb9f00 102.67%);
    color: white;
    border: none;
    font-size: 20px;
}

.karen-flex {
    display: flex;
    flex-direction: row;
  }
  

.karen-img {
    height: 100%;
    width: 100;
  max-height: 450px;
  max-width: 450px;
  border-radius: 15px;
  margin-right: 100px;
}


@media screen and (max-width: 1300px) {

    #karen-container {
        margin-top: 150px;
        margin-bottom:50px;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
       }

    .quote-h1-karen {
        font-size: 22px;
        
        line-height: 30px;
        margin-bottom: -30px;
    }

    .quotation-mark-v1-karen {
        font-size: 100px;
    }

    .quotation-mark-v2-karen {
        top: 35%;
        left: 50%;
        font-size: 100px;
    }

    .quotation-mark-v3-karen {
        margin-left: 80%;
        font-size: 100px;
    }

    .karen-flex {
        flex-direction: column;
    }

    .karen-img {
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        border-radius: 15px;
        margin-right: auto;
      }

      .quote-karen {
        width: 100%;
        margin-top: 80px;
      }

    .sub-quote-karen {
        margin-top: -30px;
        margin-bottom: 30px;
        margin-left: 40px;
      }

}
    

