
.AAAA {
    margin-left: 100px;
    margin-bottom: 10px;
    max-width: 1870px;
    margin-top: 200px;
}
.services__features {
    width: 100%;
    display: flex;
    max-width: 1500px;
}

.services__features-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    max-width: 500px;
    text-align: left;
    margin-right: 300px;
}

.services__features-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
     margin-left: 100px;
    
}

.services__features-heading p {
    color: var(--color-subtext);
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    font-family: var(--font-family);
    width: 1000px;
    margin-top: 2rem;

}

.services_features-container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: left;
    flex-direction: column;
    width: 1000px;

}


#css {
    margin-top: 70px;
    border-radius: 15px;
    width: 550px;
    height: 550px;
    margin-left: 100px;
}

.flexbox{
display: flex;
}

.we-match {
    color:var(--color-text); 
    border-radius: 15px;
    padding: 20px 20px 20px 20px;
    font-family: var(--font-family);
    max-width: 600px;
    margin-left: 150px;


    box-shadow: 0 0 150px rgba(255, 64, 0, 0.766);
}


.we-match h2 {
 color: white;
 margin-top: 30px;
 margin-bottom: 10px;
} 
.we-match p {
 font-size: 16px;
 margin-top: 10px;
} 

.icon-and {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

}



.icon-and p {
    font-size: 16px;
    font-weight: bold;
}

.icon-no-style {
    fill:red;
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 15px;
}

.icon-love{
    fill:rgb(11, 200, 11);
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 15px;
}

.mentality {
    font-family: var(--font-family);
    margin-left: 140px;
    margin-top: 130px;
}

.m-header {
    color: var(--color-text);
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 100px;
}

.m-header h1 {
    color: var(--gradient-text);
   

}


.m-list {
    display: flex;
    flex-direction:row;
    align-items: center;
    margin-top: 30px;
}

.line {
    border-left: 3px solid #00ffb2;
    height: 650px;
    width: 2px;
    margin-right: 40px;

}

.line-steps {
    margin-top: -80px;
 
}

.number-and-p{
    display: flex;
    flex-direction:row;
    align-items: center;
 
}

.number-and-p h1 {
font-size: 70px;

}



.line-p {
    color: white;
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
    font-family: var(--font-family);
}
.line-pp {
    color: white;
    font-size: 16px;
    margin-left: 10px;
    color:var(--color-text);
    max-width: 600px;
    font-family: var(--font-family);
}

#line-4-s {
    margin-top: -50px;
}

#line-4 {
    margin-top: 60px;
}

#line-5-s {
    margin-top: -20px;
}

#line-5 {
    margin-top: 40px;
}

.mentality-ref {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 550px) {
    .AAAA {
      margin-left: 30px;
    }

    #css {

        width: 300px;
        height: 300px;
        margin-left: 0px;
    }

    .we-match {
        margin-left: 0px;
        max-width: 400px;
        margin-top: 200px;
    }

    .services__features-heading h1 {
        font-size: 28px;
        line-height: 38px;
        margin-left: 30px;
        max-width: 300px;
    }

    .mentality {

       margin-top: 150px;
        margin-left: 20px;
    }

    .number-and-p{
      margin-bottom: 10px;
     
    }

    #line-1 {
        margin-top: 50px;
    }
    #line-2 {
        margin-top: 10px;
    }
    #line-3 {
        margin-top: 40px;
    }

    #line-4-s {
        margin-top: -150px;
    }
    
    #line-4 {
        margin-top: 180px;
    }
    
    #line-5-s {
        margin-top: -90px;
    }
    
    #line-5 {
        margin-top: 120px;
    }

    .line-pp {
  
        font-size: 14px;
      
    }

    .mentality-ref {
        flex-direction: column;
    }

}

@media screen and (max-width: 700px) {

}

@media screen and (max-width: 1300px) {
    .services__features {
        flex-direction: column;
    }

    .services__features-heading {
        margin: 0 0 2rem 0;
        max-width: 300px;
    }

    .we-match {
        margin-left: 0px;
        max-width: 800px;
        margin-top: 200px;
    }

    .mentality-ref {
        flex-direction: column;
    }
}


@media screen and (max-width: 550px) {

}