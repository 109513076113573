.verification-test {
  font-family: var(--font-family);
  border-radius: 25px;
  padding: 10px 10px 10px 10px;
  margin-bottom: -50px;
  width: 100%;
  height: 100%;

}

.Hmv__header__h1 {
  font-family: var(--font-family);
  color: var(--color-text);

}

.question-header {
  margin-bottom: 20px;
}


.image-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;

}


.image img {
  max-width: 100%; 
  height: 50px; 
  width: 50px;
}


.image {
  position: relative;
  cursor: pointer;

}


.selected {
  border-radius: 25px;
  background-color: var(--color-text);
}


@media (max-width: 768px) {
  .image-grid {
  margin-top: 30px;
  padding-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .image img {
    max-width: 100%; 
    height: 40px; 
    width: 40px;
  }
}

@media (max-width: 768px) {
  .verification-test {

    width: 300px;
    height: 300px;
    margin-bottom: 80px;
  }
}


@media (max-width: 768px) {
  .question-header {
    margin-bottom: 50px;
  }
}



