.FOBM__footer {
  height: 100%;
    background: var(--gradient-text);
    font-family: var(--font-family);
    color: white;
}

.footer-container {
    max-width: 1500px;
    margin: auto;
}


.FOBM__footer-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 100px;
    margin-left: -130px;
 
}
.FOBM__footer-links_div {
    margin-bottom: 20px;

}

.FOBM__footer-links_div h4 {
    margin-top: 10px;
 margin-bottom: 10px;
 font-size: 30px;
}

.FOBM__footer-links_div hr {
width: 60px;
margin-bottom: 5px;
}

.FOBM__footer-links_div p {
margin-bottom: 5px;
}

.FOBM__footer-copyright {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -30px;
    margin-top: 100px;  
}

.FOBM__footer-copyright p {
    margin-top: 10px;
}

#int-logo {
    width: 48px;
    height:48px;
    margin-top: 10px;
    border-radius: 50%;
    margin-right: 5px;
}

.link-logos {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
}


@media screen and (max-width: 850px) {
    
}

@media screen and (max-width: 1000px) {
    .FOBM__footer-links {
        flex-direction: column;
        margin-left: 20px;
    }

    .FOBM__footer-links_div h4 {
     font-size: 20px;
    }

    .FOBM__footer-links_div p {
       font-size: 12px;
        }
    

.FOBM__footer-copyright {
font-size: 10px;

}
}
