.privacy-div {
    font-family: var(--font-family);
  
}

.privacy-div ul {
    color: var(--color-text);
    line-height: 30px;
}

.privacy-div li {
 
}

.privacy-div p {
    color: var(--color-text);
    line-height: 30px;
}

.privacy-div h1 {
    color: var(--color-text);
    margin-top: 30px;
}

.privacy-div h2 {
    color: var(--color-text);
    margin-top: 30px;
}

.privacy-div h3 {
    color: var(--color-text);
    margin-top: 30px;
}





