#ref-container {
    box-shadow: 0 0 150px rgba(225, 6, 6, 0.5);
    border-radius: 15px;
    margin: 150px auto 0; /* Top margin of 150px and auto for left and right margins to center */
    justify-content: center;
    width: 35%;
}

.quote {
    margin-top: 50px;
    position: relative;
 
  }

  .sub-quote {
    margin-top: -70px;
    margin-left: 40px;
  }

.quotation-mark-v1 {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
font-family: var(--font-family);
background: linear-gradient(89.97deg, #de130c 1.84%, #fb9f00 102.67%);
-webkit-background-clip: text;
background-clip: text;
color: transparent;
font-weight: bold;
font-size: 160px;
}

.quotation-mark-v2 {
    top: 55%;
    left: 75%;
    position: absolute;
    transform: translateY(50%);
font-family: var(--font-family);
background: linear-gradient(89.97deg, #de130c 1.84%, #fb9f00 102.67%);
-webkit-background-clip: text;
background-clip: text;
color: transparent;
font-weight: bold;
font-size: 160px;
text-align: end;
}


.quotation-mark-v3 {
font-family: var(--font-family);
background: linear-gradient(89.97deg, #de130c 1.84%, #fb9f00 102.67%);
-webkit-background-clip: text;
background-clip: text;
color: transparent;
font-weight: bold;
font-size: 160px;
margin-left: 90%;

}

.quote-h1   {
    font-family: var(--font-family);
    font-size: 20px !important;
    font-weight:bold;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: -50px;
    justify-content: center;
    margin-left: 40px;
}

.quote-h2 {}

.quote-h3 {
    font-weight:400;
}

.quote-button {
    margin-top: 20px;
    font-family: var(--font-family);
    border-radius: 15px;
    padding: 10px;
    background: linear-gradient(89.97deg, #de130c 1.84%, #fb9f00 102.67%);
    color: white;
    border: none;
    font-size: 20px;
}



@media screen and (max-width: 1300px) {

    #ref-container {
        margin-top: 150px;
        margin-bottom:50px;
        width: 80%;
       }

    .quote-h1 {
        font-size: 20px;
        line-height: 30px;
   
        margin-bottom: -30px;

    
    }

    .quotation-mark-v1 {
        font-size: 100px;
    }

    .quotation-mark-v2 {
        top: 35%;
        left: 50%;
        font-size: 100px;
    }

    .quotation-mark-v3 {
        margin-left: 80%;
        font-size: 100px;
    }

    .sub-quote {
        margin-top: -30px;
        margin-bottom: 30px;
      }

}
    

