.navbar {
    margin-top: 30px;
    margin-left: 100px;
    display: flex;
    justify-content: space-between; /* Space between logo and other content */
    align-items: center; /* Align items vertically in the center */
  }
  
  .navbar-links {
    display: flex;
    align-items: center; /* Align items vertically in the center */

  }
  
  .navbar-links_logo {
    margin-right: 10px; /* Adjust margin between logo and buttons */
  }
  
  .navbar-links_container {
    display: flex;
  }
  
  .nav-link {
    padding: 5px;
    margin-right: 5px;
    /* Add any other necessary styling */

  }


.navbar-links_logo {
    margin-right: 1rem;
 
}

#another-logo {
    height:50px ;
    width:50px ;
}

.navbar-links_logo img {
    width: 62.56px;
    height: 16.02px;
}

.logo-text {
    color: white;
    font-family: var(--font-family);
    font-size: 20px;
}

#nav-links {


    color: white;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    font-weight: bolder;
}

#nav-links:hover::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(103.22deg, #de130c 1.84%, #fb9f00 102.67%);
}

#nav-links-academy {
    position: relative; /* To position the notification circle */
  
    color: white;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    font-weight: bolder;
    /* Additional styles for the notification circle */
    overflow: visible;
}

#nav-links-academy::before {
    content: "1"; 
    position: absolute;
    top: -5px; 
    right: -5px; 
    background-color: red; 
    color: white;
    width: 19px; 
    height: 19px;
    border-radius: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px; 
    font-weight: bold;
}

#nav-links-academy::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(103.22deg, #de130c 1.84%, #fb9f00 102.67%);
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition */
}

#nav-links-academy:hover::after {
    opacity: 1; /* Show on hover */
}






.logo-text-media {
    color: white;
    font-family: var(--font-family);
    font-weight: 100;
    font-size: 20px;
}

.navbar-links_container {
    display: flex;

}

.navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-links_container p,
.navbar-sign p,
.navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.navbar-sign button,
.navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: var(--gradient-text);
    font-family: var(--font-family);
    font-weight: bolder;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;

    position: fixed;
    right: 0; /* Adjust the right position as needed */
    top: 20px; /* Adjust the top position as needed */
    z-index: 9999; /* Ensure it's above other content */
    margin-right: 20px;
 
}

.navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.navbar-menu svg {
    cursor: pointer;
}

.navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.navbar-menu_container p {
    margin: 1rem 0;
}

.navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
 
    #nav-links {
        border: 1.5px solid rgba(255, 255, 255, 0.637);

      font-size: 11px;
      padding: 5px 5px 5px 5px;
      margin-right: -5px;
    
    }

    #nav-links-academy {
        border: 1.5px solid rgba(255, 255, 255, 0.637);

        font-size: 11px;
        padding: 5px 5px 5px 5px;
        margin-right: -5px;
    }
}

@media screen and (max-width: 480px) {
    .navbar-links_container {
      margin-top: 10px;
      flex-wrap: wrap; /* Allow wrapping on smaller screens */
      
    }
  
    .nav-link {
      flex: 1 0 100%; /* Make each link take full width on smaller screens */
      margin-bottom: 5px; /* Add space between wrapped links */
   
    }



    .navbar {
        margin-top: 20px;
        margin-left: 30px;
       margin-bottom: 60px;
       
      }

      #nav-links-academy {
        font-size: 11px;
        padding: 5px 5px 5px 5px;
        margin-right: -5px;
        margin-right: 20px;
        margin-top: 5px;
    }

  }



