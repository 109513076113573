/* PopupContent.css */

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;


}

.popup-container {
  background:radial-gradient(circle at 30% -100%, #06101f 25%, #040C18 85%, #050f1e 100%);
  padding-left: 5%;
  padding-right: 5%;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  overflow: auto; 
}

@media screen and (max-width: 1000px) {
  /* Customizing feature component as per needs */
  .popup-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.slide-container {
  /* Add any styles you need for the slider container */
}

.popup-header {
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FOBM__PopupContent__closeButtonOne {
  background: var(--color-bg);
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.bi-bi-x {
  fill: var(--color-subtext);
  margin-right: -20px;
}



.FOBM__h1__GT {
  background: var(--gradient-text);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  width: 600px;
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: var(--font-family);
}

@media screen and (max-width: 710px) {
  /* Customizing feature component as per needs */
  .FOBM__h1__GT {
    font-size: 30px;
    width: 100%;
  }
}

.FOBM__h2__GT {
  color: white;
  font-family: var(--font-family);
  color: var(--color-subtext);
  margin-bottom: 5px;
  font-size: 20px;
}

@media screen and (max-width: 400px) {
  /* Customizing feature component as per needs */
  .FOBM__h2__GT {
    font-size: 20px;
    width: 100%;
  }
}

.FOBM__intro__p {
  color: var(--color-text);
  font-family: var(--font-family); 
  margin-bottom: 30pxM 
}

.slide p {
  font-family: var(--font-family);
  font-size: 18px;
  color: rgba(255, 255, 255, 0.857);
  margin-bottom: 10px;
  width: 100%;
  
}

@media screen and (max-width: 710px) {
  /* Customizing feature component as per needs */
  .slide p  {
    font-size: 16px;
  }
}

.slide ul {
  margin-top: 20px;
  list-style-type: disc; /* Set the bullet point style to a filled circle */
  padding-left: 20px; /* Add some left padding to create space for the bullet points */
  color: red; /* Change the color of the bullet points to red */
}

@media screen and (max-width: 400px) {
  /* Customizing feature component as per needs */
  .slide ul{
    padding-left: -0px;
    margin-left: -10px;
    
  }
}






.li-headline {
  font-family: var(--font-family);
  font-size: 18px;
  color: var(--color-subtext);
}

.slide li {
  font-family: var(--font-family);
  font-size: 18px;
  color: rgba(255, 255, 255, 0.857);
  margin-bottom: 10px;
  width: 100%;
  margin-left: 30px;
}

@media screen and (max-width: 400px) {
  /* Customizing feature component as per needs */
  .slide p {
    width: 100%;
    
  }
}


.FOBM__NEXT__DIV {

  margin-top: 40px;
}


.FOBM__next {
  font-family: var(--font-family);
  color: var(--color-subtext);
}


.image-gallery-description {
  color:  var(--color-text);
  font-family: var(--font-family);
}

.slider-btn-prev {
  background-color:rgba(128, 128, 128, 0.203);
  border-style: none;
  border-radius: 50%;
  padding: 5px 5px 5px 5px;
  margin-top: 10px;
  margin-bottom: 100px;
  width: 50px;
  height: 50px;
}

.slider-btn-next {
  background-color:rgba(128, 128, 128, 0.203);
  border-style: none;
  border-radius: 50%;
  padding: 5px 5px 5px 5px;
  margin-left: 15px;
  width: 50px;
  height: 50px;
  margin-bottom: 100px;
}

.bi-bi-arrow-left {
  fill: white;
}

.bi-bi-arrow-right {
  fill: white;
}


.slide-container {
  width: 100%;
}

/* Other styles... */

/* Style the left and right arrow buttons for the slider */
.image-gallery-left-nav,
.image-gallery-right-nav {
  position: absolute;
  top: 50%; /* Position the arrows in the vertical center of the container */
  transform: translateY(-50%);
  font-size: 24px;
  color: white;
  background-color: var(--color-subtext);
  padding: 10px;
  cursor: pointer;
  z-index: 10;
  border-radius: 50%; /* Make the arrows round */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: none;
}

.image-gallery-left-nav {
  left: 10px; /* Adjust the spacing for the left arrow */
}

.image-gallery-right-nav {
  right: 10px; /* Adjust the spacing for the right arrow */
}

/* Style the disabled state of the arrow buttons */
.image-gallery-icon.disabled {
  pointer-events: none;
  opacity: 0.4;
}

/* Add any other styles you need for the slider arrows */
