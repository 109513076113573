/*h1*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
/*p*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');

.cookie-intro-box {

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


.cookie-refer-logo {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 3px;
  margin-top:-150px ;
 
}


/* Styles for the consent banner */
.cookie-consent-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient-text);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: slide-in 0.5s ease-in-out forwards; /* Animation name, duration, timing, and playback mode */
}

.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  padding: 20px;
  border-radius: 20px 20px 20px 20px;
  text-align: center;
  z-index: 10000; /* Adjust the z-index as needed */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 15%;
}

.logo-name {
  display: flex;
  flex-direction: column;
}

.logo-image {
  height: 60px;
  width: 60px;
  border-radius: 50%;
 border: 5px solid #d81d082b;
 margin-top: 20px;
 margin-bottom: 0;
}

#h1-cookie-intro-v1 {
  font-size: 30px;
  margin-bottom: 10px;
  font-weight: 400;
  margin-top: 12px;
}

#h1-cookie-intro-v2 {
      font-size: 100px;
}

.media-span {

}



#cookieOptions {
  display: flex;
  flex-direction: row;
}

#cookieOptions label {
  display: block;
  margin-bottom: 10px;
}

#saveButton {
  background-color:  black;
  color: white;
  padding: 10px 15px;
  border: 2px solid#fa871c;
  border-radius: 15px;
  cursor: pointer;
  margin-left: 20px;
}

#cookieIcon {
  cursor: pointer;
}

.cookie-p {
  color: #fa871c;
  max-width: 500px;
  margin-right: 50px;
  font-family: 'Roboto', sans-serif;
}


.check-p {
margin-top:30px;
color: white;
font-family: 'Roboto', sans-serif;
}

/* Your existing styles go here */

/* Styles for the custom checkbox */
.custom-checkbox {
  display: flex;
  position: relative;
  cursor: pointer;
  margin-right: 50px;
}

.custom-checkbox input[type="checkbox"] {
  opacity: 0;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 15px;
}

.custom-checkbox .checkmark {
  position: absolute;

  height: 20px;
  width: 20px;
  line-height: 20px; /* Aligns the checkmark character vertically */
  text-align: center;
  justify-content: center;
  display: flex;
  background-color: black;
  border: 2px solid #fa871c;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: black;
}


.custom-checkbox input[type="checkbox"]:disabled + .checkmark {
  background-color: black;
  border-color: #fa871c;
}

.custom-checkbox input[type="checkbox"]:disabled + .checkmark::after {
  font-size: 16px;
  color: #fa871c;
}


#essentialCheckbox:not(:checked) + .checkmark > svg {
  display: none;
}

#statisticalCheckbox:not(:checked) + .checkmark > svg {
  display: none;
}

#marketingCheckbox:not(:checked) + .checkmark > svg {
  display: none;
}

.cookie-tick {
  fill: white
}

#essentialCheckbox-icon {
  background-color: #fa871c;
  fill: white;
  border: none;
}


/*accepted message*/



.circle-icon {
  fill: white;
  height: 40px;
  width: 40px;
  position: fixed;
  background:  #d81d08;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.cookie-icon {
  fill: white;
  height: 25px;
  width: 25px;

}


@media only screen and (max-width: 1000px) {

  .cookie-intro-box {

      top: 27%;
      left: 40%;
      transform: translate(-50%, -50%);
      margin-bottom: 130px;
  }

  #h1-cookie-intro-v1 {
      font-size: 24px;
      margin-bottom: 10px;

  }
  
  #h1-cookie-intro-v2 {
          font-size: 70px;
  }


  .cookie-consent {
      flex-direction: column;
      height: 40%;
      width:90%;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 10px;
  }

  .cookie-consent h1 {
      font-size: 30px;

  }

  .cookie-p {
      font-size: 16px;
      width:100%;
      flex-direction: row;
      margin-left: 50px;
      margin-top: 20px;
      margin-bottom: 20px;
  }
  #cookieOptions {
      flex-direction: row;
      margin-left: 60px;
      margin-top: -30px;
  }

  .check-p {
      font-size: 14px;
  }

  .custom-checkbox input[type="checkbox"]:disabled + .checkmark {
 height: 20px;
 width: 20px;
    }

    .custom-checkbox input[type="checkbox"]:checked + .checkmark {
      height: 20px;
      width: 20px;
    }

    .custom-checkbox .checkmark {
      height: 20px;
      width: 20px;
    }

    /*accepted message*/





}


@media only screen and (max-width: 280px) {

  #h1-cookie-intro-v1 {
      font-size: 15px;
      margin-bottom: -20px;
      font-weight: 400;
  }
  
  #h1-cookie-intro-v2 {
          font-size: 40px;
  }

  .custom-checkbox input[type="checkbox"]:disabled + .checkmark {
      height: 10px;
      width: 10px;
         }
   
         .custom-checkbox input[type="checkbox"]:checked + .checkmark {
          height: 10px;
          width: 10px;
         }
   
         .custom-checkbox .checkmark {
          height: 10px;
          width: 10px;
         }

         .custom-checkbox {
     
          margin-right: 20px;
        }
}

@media only screen and (max-height: 500px) {
  #h1-cookie-intro-v1 {
    font-size: 16px;
    margin-bottom: 0px;
}

#h1-cookie-intro-v2 {
  font-size: 40px;
  margin-bottom: 70px;
}

.cookie-consent {
  flex-direction: column;
  height: 65%;
  width:95%;
  align-items: center;
justify-content: center;
margin: 0 auto;
margin-bottom: 10px;
}

.cookie-consent h1 {
  font-size: 20px;
}

.check-p {
  font-size: 12px;
}

.cookie-p {
  font-size: 12px;
 margin-left:30px;
 margin-top: 30px;
}

}