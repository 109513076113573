.popup {
    position: fixed;
    bottom: 0; /* Position the popup at the bottom */
    right: 0; /* Position the popup at the right */
    margin-bottom: 20px;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    background-color: rgba(0, 0, 0, 0.77);
    border-radius: 15px;
    transform: translate(0, 0); /* Reset the transform */
    box-shadow: 0 2px 5px black;
    z-index: 1000;

    height: 60px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 2px solid red;
  }

  .popup-v2 {
width: 100%;
height: 100%;
justify-content: center;
align-items: center;
text-align: center;
   
display: flex;
  }

  a {
    width: 100%;
  }

  #dk-flag {
    height: 100%;
    width: 100px;
    border-radius: 15px;
    margin-right: 20px;
  }

  .close-div {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    border-left: 1px solid red;
    height: 100%;
  }

  .arrow-icon {
    width:30px;
    height:30px;
   fill: white;
   margin-left: 20px;
  }
  
  .close-icon {
    display: flex;
    margin: auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    width:30px;
     height:30px;
    fill: white;
    margin-left: 20px;
  }

  @media (max-width: 768px) {

    .popup {
        width: 300px;
        height: 40px;
      }

      #h1-flag {
        font-size: 13px;
      }

      #dk-flag {
        height: 100%;
        width: 60px;
        border-radius: 15px;
        margin-right: 10px;
      }

      .arrow-icon {
        width:20px;
        height:20px;
       margin-left: 10px;
      }
  }