.timebooking {
    font-family: var(--font-family);
    color: white;
    background: var(--gradient-text);
 display: flex;
 align-items: center;
 justify-items: center;
 flex-direction: column;
}
.header {
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;

}
.calendly-inline-widget {
   height:1000px;

   width: 100%;
}
