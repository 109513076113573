.services {
  
        display: flex;
        flex-direction: column;
        padding: 2rem;
        background: none;
     
    
    

    /* ff 3.6+ */
   /* background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgb(6, 63, 124) 100%);

    /* safari 5.1+,chrome 10+ */
   /* background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
   /* background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    /*background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    /*background:radial-gradient(circle at 30% -100%, #06101f00 25%, #06101f00 85%, #06101f00 100%);

    /*    background:radial-gradient(circle at 30% -100%, #091931 25%, #040C18 85%, #050f1e 100%);*/

    /*filter: drop-shadow(0px 4px 4px rgba(19, 14, 14, 0.25)); */
}

.services-feature {
    display: flex;
}

/* Customizing feature component as per needs */
.services-feature .services-container__feature {
    margin: 0;
}

/* Customizing feature component as per needs */
.services-feature .services__features-container_feature-text {
    max-width: 700px;
}

.services-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    margin: 4rem 0 2rem;
}

.services-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.services-heading p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: var(--color-subtext);
    cursor: pointer;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    margin-top: 2rem;
}

/* Customizing feature component as per needs */
.services-container .services__features-container__feature {
    flex: 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
}

/* Customizing feature component as per needs */
.services-container .services__features-container_feature-text {
    margin-top: 0.5rem;
    padding-left: 15px;
    padding-right: 10px;
    margin-left: -15px;
}

.read__more {
    background: none;
 
    font-family: var(--font-family);
    color: var(--color-subtext);
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    margin-left: -20px;
    border: 2px solid;
    border-color: var(--color-subtext);
    outline: none;
    cursor: pointer;
    min-width: 120px;
    margin-top: 5px;
}

.FOBM__IMAGE {
    width: 600px;
    height: 400px;

}

.knap button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: var(--gradient-text);
    font-family: var(--font-family);
    font-weight: bolder;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
    position: fixed;
    right: 0; /* Adjust the right position as needed */
    top: 20px; /* Adjust the top position as needed */
    z-index: 9999; /* Ensure it's above other content */
    margin-right: 20px;
}

/*web dev*/

.web-product-logo {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

#read__more__webdev {
    margin-top: 10px;
    margin-left: 0px;
}

@media screen and (max-width: 550px) {


   
    .knap button {

      height: 30px;
      top: 30px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center; /* Horizontally center the content */
      align-items: center; /* Vertically center the content */
      
    
      }
    }

@media screen and (max-width: 650px) {

    .FOBM__IMAGE {
        width: 300px;
        height: 200px;
    }
}


@media screen and (max-width: 850px) {
    .services-heading {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0rem;
    }

    .services-heading p {
        margin-top: 1rem;
    }
}

@media screen and (max-width: 650px) {
    /* Customizing feature component as per needs */
    .services-feature .services__features-container__feature {
        flex-direction: column;
    }

    /* Customizing feature component as per needs */
    .services-feature .services__features-container_feature-text {
        margin-top: 0.5rem;
    }

    .services-heading h1 {
        font-size: 28px;
        line-height: 40px;
    }
}

@media screen and (max-width: 350px) {
    /* Customizing feature component as per needs */
    .services-container .services__features-container__feature {
        margin: 1rem 0;
        min-width: 100%;
    }
}