.intro {
    background: var(--gradient-text);
    height: 100vh;
    width: 100%;
 
    font-family: var(--font-family);
    color: white;
    position: relative; /* Add relative positioning to create a stacking context */
  }
  
  .company-name,
  .new-title,
  .scroll-div {
    font-size: 20px;
  
    /*
      opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    */
  
 
  }

  .CN {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .company-name {
    animation-name: fade-in-out; /* Apply the fade-in-out animation */
    font-size: 60px;
    animation-duration: 3s; /* Set the animation duration */
    animation-timing-function: ease-in-out; /* Set the timing function */
    animation-fill-mode: forwards; /* Keeps the final state of the animation */


  }

  .new-title {
    font-size: 28px;
    margin-left: 50px;
    animation-duration: 1s; /* Set the animation duration */
    animation-timing-function: ease-in-out; /* Set the timing function */
  
  }
  

  
  @keyframes fade-in-out {
    0% {
      opacity: 0; /* Initial state, fully transparent */
    }
    50% {
      opacity: 1; /* Midpoint, fully opaque */
    }
    100% {
      opacity: 0; /* Final state, fully transparent */
    }
  }
  
  .scroll-div {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

  }

  .scroll-div_v2 {
    display: flex;

    align-items: center;
    justify-content: center;



  }

  .a-name {
    font-size: 60px;

  }

  .blinker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    animation-name: blink; /* Apply the blink animation for scroll-div */
    animation-duration: 2s; /* Set the animation duration for blinking */
    animation-timing-function: linear; /* Linear timing for a constant blinking effect */
    animation-iteration-count: infinite; /* Make the animation repeat indefinitely */
  }

  .blinker p{
    justify-content: center;
    font-size: 12px;
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 0; /* Initial and final state, fully transparent */
    }
    50% {
      opacity: 1; /* Midpoint, fully opaque */
    }
  }
  
  .intro-scroll {
    fill: white;
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
  }

  @keyframes fade-out {
    0% {
      opacity: 1; /* Fully visible at the start */
    }
    100% {
      opacity: 0; /* Completely transparent at the end */
    }
  }

  .logo-text-media_intro {
    color: white;
    font-family: var(--font-family);
    font-weight: 100;
    font-size: 20px;
    font-size:60px;
    margin-left:10px;
}

  #a {
    animation-name: fade-out;
    animation-duration: 7s; /* One second duration */
    animation-fill-mode: forwards; }
  #aa {
    animation-name: fade-out;
    animation-duration: 8s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaa {
    animation-name: fade-out;
    animation-duration: 9s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaa {
    animation-name: fade-out;
    animation-duration: 10s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaa {
    animation-name: fade-out;
    animation-duration: 11s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaa {
    animation-name: fade-out;
    animation-duration: 12s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaaa {
    animation-name: fade-out;
    animation-duration: 13s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaaaa {  animation-name: fade-out;
    animation-duration: 14s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaaaaa {  animation-name: fade-out;
    animation-duration: 15s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaaaaaa {  animation-name: fade-out;
    animation-duration: 16s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaaaaaaa {  animation-name: fade-out;
    animation-duration: 17s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaaaaaaaa {  animation-name: fade-out;
    animation-duration: 18s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaaaaaaaaa {  animation-name: fade-out;
    animation-duration: 19s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaaaaaaaaaa {  animation-name: fade-out;
    animation-duration: 20s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaaaaaaaaaaa {  animation-name: fade-out;
    animation-duration: 21s; /* One second duration */
    animation-fill-mode: forwards; }
  #aaaaaaaaaaaaaaaa {  animation-name: fade-out;
    animation-duration: 22s; /* One second duration */
    animation-fill-mode: forwards; }
  

  @media screen and (max-width: 1200px) {

    .company-name  {
        font-size: 20px;
    }

    .a-name {
        font-size: 20px;
    
      }

      .blinker { 
        margin-top: 40px;
      }

      .logo-text-media_intro {
   
        font-size:20px;
        margin-left:5px;
    }

    
  }
  