.dashboard-yo-yo {
    margin-top: 200px;
}
.dashboard___heading {
    font-family: var(--font-family);
}

.dashboard p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text);

}

#dashboard-p-one{
    margin-top: 30px;
}

.dashboard h2 {
    font-family: var(--font-family);
    color:var(--color-text);
    margin-top: 30px;
    margin-bottom: -30px;
}

.buttonholder {
    margin: auto;
    text-align: center;

}

.dashboard___features {
    margin-top: 50px;
    margin-left: -15px;
    width: 90%;
}

.usp___dashboard {
    display: flex;
    margin-top: 50px;

}
.db-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dashboard___button{

    padding: 0.5rem 1rem;
    color: #fff;
    background: var(--gradient-text);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 25px;
    margin-top: 30px;
    width: 500px;

}


.icon___box {
    font-family: var(--font-family);
    line-height: 25px;
    padding: 0.5rem 1rem;

    width: 100%;
    display: flex;
    margin-top: 10px;
    margin-right: 10px;
}

.dashboard___icons {
    height: 25px ;
    width: 25px ;
    fill: var(--color-text);
    margin-right: 10px;
}

.dashboard-img img {
    width:500px;


}

@media screen and (max-width: 800px) {


    .icon___box {
       
        width: 100%;
    }

    .usp___dashboard {
       flex-direction: column;
    
    }

    .dashboard___features {
        margin-left: 0px;
    }

    .dashboard-img img {
        width:400px;
       display: flex;
       justify-content: center;
       align-items: center;
       margin-left: -30px;
   
    }

    .dashboard p {
 
        font-size: 14px;
     
    
    }
}
