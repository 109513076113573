
  
  

.a__header {
    display: flex;
    flex-direction: row;
}

.a__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    margin-right: 5rem;
}

.a__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04em;

 
}

.a__header-content p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: var(--color-text);

    margin-top: 1.5rem;
}

.a__header-content__input__v2 {
    width: 100%;
    margin: 2rem 0 1rem;
    flex-direction: column;
    display: flex;
   
}



.a__header-content__input__v2 input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: rgba(0, 0, 0, 0.107);
    border: 2px solid var(--color-text);
    padding: 0 1rem;
    outline: none;
    color: var(--color-text);
    margin-top: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    margin-bottom: 10px;
}

.a__header-content__input__v2 ::placeholder {
color: var(--color-text);
}

.message-box {
    flex: 2;
    margin-top: 5px;
    width: 100%;
    min-height: 100px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: var(--color-footer);
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color:var(--color-text);

    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.a__header-content__input__v2 button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background:var(--gradient-text);
    border: 2px solid var(--gradient-text);
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    outline: none;

    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}



.a__header-content__people {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    margin-top: 2rem;
}

.a__header-content__people img {
    width: 100px;
    height: 38px;
}

.a__header-content__people p {
    margin: 0 0 0 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 12px;
    line-height: 38px;
    color: #fff;
    text-align: center;
}

.a__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.a__header-image img {
    width: 100%;
    height: 100%;
}

#videoPlayer {
    border-radius: 30px;
    width: 690px;
    height: 390px;
    box-shadow: 0 0 150px rgba(255, 64, 0, 0.766);

}

.experience {
    margin-top: 40px;
    margin-bottom: 60px;
}

#years {
    font-family: var(--font-family);
    background: linear-gradient(89.97deg, #de130c 1.84%, #fb9f00 102.67%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-weight: bold;
    font-size: 60px;
}


.years-of-exp {
    font-family: var(--font-family);
    color: white;
    margin: auto;
    display: flex;
    text-align: center;
   justify-content: center;
   font-size: 40px;
}


@media screen and (max-width: 1200px) {
    .a__header {
        flex-direction: column;
    }

    .a__header-content {
        margin: 0 0 3rem;
    }

    #videoPlayer {
     
        width:600px;
         height:340px;
       border-radius: 15px;
    }


}

@media screen and (max-width: 650px) {
    .a__header-content h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .a__header-content p {
        font-size: 16px;
        line-height: 24px;
    }
    
    .a__header-content__people {
        flex-direction: column;
    }

    .a__header-content__people p {
        margin: 0;
    }

    .a__header-content__input__v2 input,
    .a__header-content__input__v2 button,
    .message-box {
        font-size: 16px;
        line-height: 24px;
    }

    #videoPlayer {
        height: 260px;
        width: 459px;
        border-radius: 15px;
    }

    .experience {
        margin-top: 40px;
        margin-bottom: 60px;
    }
    
    #years {
    
        font-size: 20px;
    }
    
    
    .years-of-exp {
     
       font-size: 20px;
    }
    
}

@media screen and (max-width: 490px) {

    .a__header-content{
        margin-top: -50px;
    }

    .a__header-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .a__header-content p {
        font-size: 14px;
        line-height: 24px;
    }

    .a__header-content__input__v2 input,
    .a__header-content__input__v2 button {
        font-size: 12px;
        line-height: 16px;
    }

    #videoPlayer {
        height: 198px;
        width: 350px;
       border-radius: 15px;

    }
}