/*h1*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');
/*p*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300&display=swap');






* a {
    text-decoration: none; 
    color: inherit;
}

* h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
     font-size: 50px;
    color: white;
}

* h2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
     font-size: 20px;
    color: white;
}

/* */


:root {
    --gradient-color-1: #de130c;
    --gradient-color-2: #fb9f00;
  }
  
  .foba {
    display: flex;
    margin-top: 200px;
    border-radius: 15px;
    padding: 50px;
    margin-left: 10px;
  }

  .full-of-beans-academy {
    background-color:rgb(8, 8, 8);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 0 150px rgba(255, 64, 0, 0.766);
  }

  #full-of-beans-academy {
    margin-top: 300px;
  }


  .full-of-beans-academy h1 {
    margin-left: 45px;
   font-size: 30px;
   margin-top: 75px;
  }

  .foba-video {
    height: 100%;
    width: 100%;
    max-width:600px;
    max-height:1000px;
    border-radius: 15px;
    margin-top: -100px;
    margin-left: -30px;
    margin-right: 40px;

  }

  .foba-elements {
  margin-top: -280px;
  }


  .foba-elements-h2 {
    margin-bottom: 30px;
  }

  /*
  .foba::after {
    content: "";
    position: absolute;
    top: -20px;
    left: -20px;
    right: -20px;
    bottom: -20px;
    z-index: -1;
    border-radius: 15px;
    background: linear-gradient(89.97deg, var(--gradient-color-1) 1.84%, var(--gradient-color-2) 102.67%);
    filter: blur(70px); /* Adjust blur as needed 
  } 

/**/


#academy-gif {
    width: calc(50% - 10px); /* Adjust as needed */
    margin-bottom: 20px; /* Adjust as needed */
    width: 350px;
    height: 200px;
    border-radius: 15px;
    margin-right: 20px;
}

  .h1-p-emailalert {
    width: 70%;
    margin: auto;
  }

  .h1-p-emailalert h1 {
    margin-top: 20px;
   
  }

  .h1-p-emailalert h2 {
    margin-top: 20px;
    margin-left: 15px;
  }

  .h1-p-emailalert p {
   margin-top: 20px;
   margin-bottom: 20px;
   line-height: 23px;
   color: white;
   font-family: 'Roboto', sans-serif;
  }

  /*Buttons*/

  .buttons {
    margin-bottom: 30px;
  }

  .cta-button {
    border-radius: 8px; 
    background-color: white;
    color: black;
    font-size: 18px; 
    border: none;
    padding: 10px 20px; 
    text-align: center;
    display: inline-block; 
    cursor: pointer; 
    margin-right: 5px;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    margin-left: 0px;
  }
  
  .cta-button:hover {
    background-color: rgba(255, 255, 255, 0.102);
    border: 2px solid white;
    color: white;
  }

  .button-arrow {
    width:16px;
     height:16px;
     fill:currentColor;
     margin-bottom: -2px;
  }

  .cta-button-alt {
    border-radius: 8px; 
    background-color: black;
    color: white;
    font-size: 18px; 
    border: 2px solid white;
    padding: 10px 20px; 
    text-align: center;
    display: inline-block; 
    cursor: pointer; 
    margin-right: 5px;
  }

  .cta-button-alt:hover {
    background-color: rgba(255, 255, 255, 0.102);

  }




/*Icon */

.all-icons {
  fill:white;
  height:30px;
  width: 30px;
  margin-right: 20px;
  margin-bottom: 10px;
}

#icon-1 {
fill:rgb(228, 81, 2);
}
#icon-2 {
 fill: #8853f1;
}
#icon-3 {
fill: rgb(186, 230, 11);

}
#icon-4 {
  fill: rgb(230, 11, 99);

}
#icon-5 {
fill:rgb(11, 201, 230);

}

.foba-service {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
}

  
  /* Media Query for Phones (up to 767px) */
  @media only screen and (max-width: 1300px) {

    .foba-video {
      height: 120%;
      width: 120%;
      margin-top: 50px;
      margin-bottom: 50px;
      box-shadow: none;
      max-width: 1000px;
    }

    #full-of-beans-academy {
      margin-top: 200px;
    }

    .foba-elements {
      margin-top: 0px;
      }
    

    .foba {
      border-radius: 15px;
      padding: 0px;
      width: 80%;
      margin: auto;
      flex-direction: column;
    }
  
    .foba::after {
      top: -10px;
      left: -10px;
      right: -10px;
      bottom: -10px;
      border-radius: 15px;
      filter: blur(30px);
    }
  
    .foba-flexbox {
        display: flex;
        flex-direction: column;
        margin: auto;
    }

    #nick-img {
        width: 300px;
        height: 300px;
        border-radius: 15px;
        margin-left: 10px;
        margin-bottom: 30px;
        margin-top: 0px;
      }

      .h1-p-emailalert {
        width: 330px;
      }

      .h1-p-emailalert h1 {
        font-size: 35px;
        margin-left: 10px;

      }

      .h1-p-emailalert p {
        font-size: 14px;
        margin-left: 10px;
      }

      /*BUTTONS*/

      .buttons {
        margin-left: 10px;
      }

      /*EMAIL*/

      .email-alert {
        background-color: rgb(23, 22, 22);
        border-radius: 15px;
        padding: 5px;
        width: 300px;
        margin-left: 10px;
        margin-bottom: 20px;
      }
    
      .email-alert h2 {
        margin-left: 20px;
      }
    
      .email-alert p {
        margin-left: 20px;
        margin-top: -0px;
      }
    
      .email-alert-flex {
        display: flex;
        flex-direction: column;
      }
    
    
      .email-alert input {
        border-radius: 15px;
        height: 40px;
        width: 250px;
         border:none;
         margin-right: 10px;
      }
    
      .email-alert input::placeholder {
       margin-left: 20px;
       font-size: 12px;
      
      }
    
      .email-alert-submit-button {
        border-radius: 15px;
        display: flex;
        align-items: center; 
        width: 180px;
        height: 43px;
        border: 2px solid #6e40c9;
        background-color: #6e40c9;
        color: white;
        padding: 0 15px; 
        font-size: 12px;
        margin-top: 10px;
      }
      
      .bell-icon {
        fill: white;
        height: 15px;
        width: 15px;
        margin-left: 10px;
      }

      .cta-button {
        margin-left: 20px;
      }

    }