.animate {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1.5s, transform 0.5s;
  }
  
  .animate.visible {
    opacity: 1;
    transform: translateY(0);
  }
  